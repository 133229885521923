<nb-card fullWidth>
  <nb-card-body class="d-flex">
    <div class="col-2 date-range-box">
      <!-- <div class="filter-label">{{ dateRangeSelectionText }} Range</div>
      <nz-range-picker
        data-testid="e2e-picker-segment-period"
        class="form-control range-picker"
        [nzMode]="dateRangeSelectionText.toLowerCase()"
        [formControl]="formControl_selectedRange"
        [nzPlaceHolder]="'Select ' + dateRangeSelectionText + ' Range'"
        [nzAllowClear]="false"
        [nzDisabledDate]="selectableDateRangeFn | async | defaultTo: noDateAllowed"
        [nzRanges]="presettedDateRanges | async | defaultTo: {}"
      ></nz-range-picker> -->
      <span>AAAAAAAAAAAAAAAAA</span>
    </div>
  </nb-card-body>
</nb-card>
