import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'cel-scenario-download-dialog',
    templateUrl: './scenario-download-dialog.component.html',
    styleUrls: ['./scenario-download-dialog.component.scss'],
  })

export class ScenarioDownloadDialogComponent implements OnInit{
    ngOnInit(): void {
        throw new Error("Method not implemented.");
    }

}